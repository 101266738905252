export const columnConfig = [
  {
    key: 'partner_name',
    label: 'Partner',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
      minWidth: '200px',
      textAlign: 'left',
    },
    tdClass: 'text-black',
    thClass: 'font-semibold',
    class: 'p-1',
  },
  {
    key: 'telephone',
    label: 'Kontak',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
      minWidth: '200px',
      textAlign: 'left',
    },
    tdClass: 'text-black',
    thClass: 'font-semibold',
    class: 'p-1',
  },
  {
    key: 'used_card_count',
    label: 'Kuota Kartu',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
      minWidth: '200px',
      textAlign: 'left',
    },
    tdClass: 'text-black',
    thClass: 'font-semibold',
    class: 'p-1',
  },
  {
    key: 'total_spending',
    label: 'Spending Bulanan',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
      minWidth: '200px',
      textAlign: 'left',
    },
    tdClass: 'text-black',
    thClass: 'font-semibold',
    class: 'p-1',
  },
  {
    key: 'last_transaction',
    label: 'Transaksi Terakhir',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
      minWidth: '200px',
      textAlign: 'left',
    },
    tdClass: 'text-black',
    thClass: 'font-semibold',
    class: 'p-1',
  },
  {
    key: 'more',
    label: '',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
      minWidth: '200px',
      textAlign: 'left',
    },
    tdClass: 'text-black',
    thClass: 'font-semibold',
    class: 'p-1',
  },
]

export const status = []
